import { Tooltip } from '@visx/tooltip';
import { mode } from 'd3-array';
import HelpTooltip from 'generic/components/HelpTooltip';
import Transition from 'generic/components/Transition';
import { isPolygon } from 'generic/layers/GeometryLayer';
import TypedFeature from 'generic/layers/TypedFeature';
import {
  type BeaconFeatures,
  type DeskFeatures,
  type DrawingDeskFeatures,
  type FloorRoomMapFeatures,
  type FloorRoomMapProps,
  type FloorStoredOffsets,
  ModuleType,
  OtherType,
  type RoomFeatures,
  type Sensor,
  type SmartModuleFeatures,
  type StoredOffsets,
} from 'mda2-frontend/src/common/types';
import {
  INTERSECTED_PARAM,
  LS_DESK_OFFSETS,
  LS_DESK_ROTATION,
  LS_SHOW_BEACON_LABEL,
  LS_SHOW_DESK_INDEX,
} from 'mda2-frontend/src/constants';
import { CANVAS_EXPORT_MAP_ID } from 'mda2-frontend/src/generic/components/CanvasExportButton/CanvasExportButton';
import LayerInfoTogglers, {
  type SwitchInterface,
} from 'mda2-frontend/src/generic/components/LayerInfoTogglers/LayerInfoTogglers';
import Map from 'mda2-frontend/src/generic/components/Map';
import Panel from 'mda2-frontend/src/generic/components/Panel';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import {
  type FloorMapFeaturesQuery,
  useFloorMapFeaturesQuery,
} from 'mda2-frontend/src/graphql/types';
import hiX from 'mda2-frontend/src/img/heroicons_x.svg';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useStore from 'model/store';
import { Collection } from 'ol';
import Feature from 'ol/Feature';
import OLMap from 'ol/Map';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import type { Coordinate } from 'ol/coordinate';
import type { EventsKey } from 'ol/events';
import { type LineString, MultiPoint } from 'ol/geom';
import Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import Draw from 'ol/interaction/Draw';
import type OLVectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import { useEffect, useMemo, useState } from 'react';
import { FaAudioDescription } from 'react-icons/fa';
import {
  HiInformationCircle,
  HiOutlineCube,
  HiOutlineServerStack,
} from 'react-icons/hi2';
import { FormattedMessage } from 'translations/Intl';
import useDecodedLocation from 'utils/useDecodedLocation';
import AddDesksCard from './components/AddDesksCard';
import AddModuleCard from './components/AddModuleCard';
import AddRoomCard from './components/AddRoomCard';
import CanvasButton from './components/CanvasButton';
import MapActionButtons from './components/MapActionButtons';
import MeasureDistanceCard from './components/MeasureDistanceCard';
import PrivateBeaconModal from './components/PrivateBeaconModal';
import SelectNewBeaconCard from './components/SelectNewBeaconCard';
import type DeskMoveEvent from './interactions/deskMoveEvent';
import { DeskMoveEventType } from './interactions/deskMoveEvent';
import type MoveBeacon from './interactions/moveBeacon';
import {
  getDeskRotation,
  getOffset,
  isDrawingDeskFeature,
} from './interactions/moveDesk';
import type MoveEvent from './interactions/moveEvent';
import { MoveEventType } from './interactions/moveEvent';
import { RotateEventType } from './interactions/rotateEvent';
import {
  baseLayer,
  beaconMoveInteractions,
  beaconsLayer,
  beaconsModuleLayer,
  deskMove,
  deskOutlineLayer,
  deskOutlineSource,
  desksLayer,
  dragBox,
  drawMeasureDistanceLayer,
  drawMeasureDistanceLine,
  drawMeasureDistanceSource,
  drawRoom,
  drawingBeaconLayer,
  drawingBeaconSource,
  drawingDesksLayer,
  drawingDesksSource,
  drawingRoomLayer,
  drawingRoomSource,
  extraLimitLayer,
  modifyRoomLayer,
  modifyRoomSource,
  roomLayer,
  roomModify,
  roomOutlineLayer,
  roomOutlineSource,
  rotateAnchorLayer,
  rotateAnchorSource,
  rotateBeacon,
  select,
  snapLineForBeaconInteraction,
  snapLineForBeaconLayer,
  snapLineForBeaconSource,
  snapLineForRoomInteraction,
  snapLineForRoomLayer,
  snapLineForRoomSource,
} from './mapElements';
import {
  type FallbackOffsets,
  beaconStyle,
  coordinatesMode,
  createDeskOutlineFeats,
  createRoomOutlineFeats,
  detectBeaconLeavesPrivateRoom,
  getBeacons,
  getCursor,
  getDesks,
  getFilteredSnapLines,
  getInitialDesksFeats,
  getInitialStoredRotation,
  getIntersectedElements,
  getIntersectedRooms,
  getRooms,
  hasDeskInUseSensors,
  hasModuleSensors,
  isSnap,
  reRenderDeskOutlineFeatures,
  resetFeatureGeometry,
  resetIntersectedRooms,
  resetMapInteractions,
} from './utils/helpers';

export default function FloorRoomMap({
  floor,
  buildingName,
  panelOpen,
  setPanelOpen,
  activateMeasureDistanceInteraction,
  setActivateMeasureDistanceInteraction,
}: FloorRoomMapProps): JSX.Element {
  const olListenersKeys: EventsKey[] = [];
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);
  const room = useDecodedLocation('room');
  const beacon = useDecodedLocation('beacon');
  const [map] = useState(new OLMap({}));
  const [mapLayers] = useState<any[]>([
    baseLayer,
    roomLayer,
    drawingRoomLayer,
    modifyRoomLayer,
    beaconsLayer,
    desksLayer,
    drawingDesksLayer,
    beaconsModuleLayer,
    drawingBeaconLayer,
    // Shows the outline of the desks, when clicking on a corresponding beacon
    deskOutlineLayer,
    // Shows the outline of the selected rooms in order to scale/move them
    roomOutlineLayer,
    rotateAnchorLayer,
    snapLineForBeaconLayer,
    snapLineForRoomLayer,
    extraLimitLayer,
    drawMeasureDistanceLayer,
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRooms, setSelectedRooms] =
    useState<Collection<Feature<Polygon>>>();
  const [selectedRoomsAndFeatures, setSelectedRoomsAndFeatures] = useState<
    FloorRoomMapFeatures[]
  >([]);
  const [showBeaconLabels, setShowBeaconLabels] = useState(
    localStorage.getItem(LS_SHOW_BEACON_LABEL) === 'true',
  );
  const [showDeskIndexes, setShowDeskIndexes] = useState(
    localStorage.getItem(LS_SHOW_DESK_INDEX) === 'true',
  );
  const [isSavingModule, setIsSavingModule] = useState(false);
  const [moveAndScaleButtonActive, setMoveAndScaleButtonActive] =
    useState(false);
  const [selectedRoom, setSelectedRoom] = useState<RoomFeatures | undefined>();
  const [selectedBeacon, setSelectedBeacon] = useState<
    BeaconFeatures | SmartModuleFeatures | undefined
  >(undefined);
  const [isSelectingNewBeacon, setIsSelectingNewBeacon] = useState(false);
  const [newBeacon, setNewBeacon] = useState<
    BeaconFeatures | SmartModuleFeatures | undefined
  >(undefined);
  const [isAddingRoom, setIsAddingRoom] = useState(false);
  const [newRoomGeom, setNewRoomGeom] = useState<Polygon | null>(null);
  const [newBeaconGeometry, setNewBeaconGeometry] = useState<
    Point | undefined | null
  >(null);
  const [storedOffsets, setStoredOffsets] = useState<StoredOffsets>(
    localStorage.getItem(LS_DESK_OFFSETS) &&
      localStorage.getItem(LS_DESK_OFFSETS) !== 'undefined'
      ? JSON.parse(localStorage.getItem(LS_DESK_OFFSETS) || '{}')
      : {},
  );
  const [fallbackOffsets, setFallbackOffsets] = useState<FloorStoredOffsets>();
  const [storedRotation, setStoredRotation] = useState(
    getInitialStoredRotation(floor.Id),
  );
  const [fallbackRadius, setFallbackRadius] = useState(10);
  const [hoveredFeature, setHoveredFeature] = useState<
    BeaconFeatures | RoomFeatures | DeskFeatures | null
  >(null);
  const [intersectedRooms, setIntersectedRooms] = useState<
    Feature<Polygon>[] | null
  >(null);
  const [openPrivateBeaconWarning, setOpenPrivateBeaconWarning] =
    useState(false);

  const [{ data: deskData, fetching: loadingDesk }] = useFloorMapFeaturesQuery({
    variables: {
      BuildingName: buildingName,
      FloorNumber: floor.Number,
      ModuleTypes: Object.values(ModuleType),
    },
    pause: typeof floor.Number !== 'number',
    context: useMemo(
      () => ({
        additionalTypenames: ['Desks', 'MqttBeacons', 'Rooms'],
      }),
      [],
    ),
  });

  const onFeaturesClick = (features: FloorRoomMapFeatures[]) => {
    if (!deskData || loadingDesk || activateMeasureDistanceInteraction) {
      return;
    }
    if (isSelectingNewBeacon) {
      // close on map click.
      setSelectedRoom(undefined);
      return;
    }
    if (isAddingRoom || newBeacon) {
      return;
    }
    // Set selected room to null to force reselect via room or beacon click.
    const [beaconFeature] = getBeacons(features);
    if (
      selectedBeacon &&
      (!beaconFeature || selectedBeacon !== beaconFeature)
    ) {
      resetFeatureGeometry(selectedBeacon);
    }
    if (beaconFeature) {
      // Close room card
      setSelectedRoom(undefined);
      setSelectedBeacon(beaconFeature);
      setNewBeaconGeometry(beaconFeature.getGeometry());
      return;
    }
    const deskFeatures = getDesks(features);
    if (deskFeatures.length && deskFeatures[0]) {
      const beaconId = deskFeatures[0].getProperties().Sensor?.MqttBeacon.Id;
      const selectedBeaconFeature = (
        (beaconsLayer.olLayer as OLVectorLayer<VectorSource<Feature<Point>>>)
          .getSource()
          ?.getFeatures() ?? []
      ).find((f) => f.get('Id') === beaconId);
      setSelectedBeacon(selectedBeaconFeature as BeaconFeatures);
      setNewBeaconGeometry(selectedBeaconFeature?.getGeometry());
      return;
    }
    const [roomFeature] = getRooms(features);
    if (roomFeature) {
      setSelectedBeacon(undefined);
      setNewBeaconGeometry(null);
      setSelectedRoom(roomFeature);
    } else {
      resetBeaconAndRoomGeometry();
    }
  };

  const resetBeaconAndDeskGeometry = () => {
    const activeDeskIds = drawingDesksSource
      .getFeatures()
      .map((f) => (f.getProperties().visible ? f.getProperties().Id : null))
      .filter((id) => typeof id === 'number');
    if (selectedBeacon && newBeaconGeometry) {
      const beaconGeom = new Point(
        selectedBeacon.getProperties().Geometry.coordinates as Coordinate,
      );
      resetFeatureGeometry(selectedBeacon);

      const deskInUseSensors = selectedBeacon
        .getProperties()
        .Sensors.filter((s) => s.SensorType.Name === OtherType.DESKINUSE);
      const initDeskFeats = getInitialDesksFeats(
        deskInUseSensors,
        deskInUseSensors.length,
        floor.Id,
        (s) => activeDeskIds?.includes(s.Id),
        fallbackRadius,
        fallbackOffsets,
        beaconGeom,
        false,
        (sensorId) => activeDeskIds?.includes(sensorId),
      );
      drawingDesksSource.clear();

      if (initDeskFeats) {
        drawingDesksSource.addFeatures(initDeskFeats);
      }

      // Force Redraw layers
      beaconsLayer.olLayer.changed();
      snapLineForBeaconLayer.olLayer.changed();
      desksLayer.olLayer.changed();
      drawingDesksLayer.olLayer.changed();
    }
    reRenderDeskOutlineFeatures(beaconsLayer.selected);
  };

  const resetDesksToInitialPositions = (activatedSensorIds: number[]) => {
    // If editing a selected beacon that already exists
    if (newBeaconGeometry) {
      const beaconSensors = (
        beaconsLayer.selected.getProperties().Sensors as Sensor[]
      ).filter((s) => s.SensorType.Name === OtherType.DESKINUSE);
      const assignedSensorIds = deskData?.Desks.map((d) => d.Sensor?.Id);
      const newDesks = getInitialDesksFeats(
        beaconSensors,
        beaconSensors.length,
        floor.Id,
        (s) => !!assignedSensorIds?.includes(s.Id),
        fallbackRadius,
        fallbackOffsets,
        newBeaconGeometry,
        false,
        (sensorId) => !!activatedSensorIds.includes(sensorId),
      );
      drawingDesksSource.clear();
      if (newDesks.length) {
        drawingDesksSource.addFeatures(newDesks);
        createDeskOutlineFeats(beaconsLayer.selected, newDesks);
      }
    }
  };

  // Initial effect to ensure correct setter in interactions.
  // biome-ignore lint/correctness/useExhaustiveDependencies: Reload the listener when the storeOffset change to ensure it takes correctly the new values
  useEffect(() => {
    unByKey(olListenersKeys);
    olListenersKeys.push(
      drawRoom.on('drawend', (evt) => {
        drawingRoomSource.clear();
        const geom = evt.feature.getGeometry() as Polygon;
        setNewRoomGeom(geom);
      }),
    );

    for (const beaconMove of beaconMoveInteractions) {
      // Only add the snap lines when moving a beacon
      olListenersKeys.push(
        // @ts-expect-error ol problems
        beaconMove.on(MoveEventType.START, (evt: MoveEvent) => {
          map.addInteraction(snapLineForBeaconInteraction);
        }),
      );
      olListenersKeys.push(
        // @ts-expect-error ol problems
        beaconMove.on(MoveEventType.END, (evt: MoveEvent) => {
          resetIntersectedRooms();
          // Remove the snap lines after ending a beacon move
          map.removeInteraction(snapLineForBeaconInteraction);
          snapLineForBeaconInteraction.snapLayer.setSnappedLineIds(null);

          const evtFeature = evt.feature as BeaconFeatures;
          const coord = (evt.target as MoveBeacon).coordinate;
          if (coord) {
            const newIntersectedRooms = getIntersectedRooms(
              new Point(coord),
              evtFeature,
            );
            detectBeaconLeavesPrivateRoom(
              evtFeature,
              newIntersectedRooms,
              setOpenPrivateBeaconWarning,
            );
            setIntersectedRooms(newIntersectedRooms);
          }
        }),
      );
    }

    olListenersKeys.push(
      // @ts-expect-error ol problems
      deskMove.on(DeskMoveEventType.END, (evt: DeskMoveEvent) => {
        // Save desk offset in state
        const deskNumber = evt.target.source.getFeatures().length;
        // offsetIndex = 0 for scenario: 3 head lamp and index = 3, else is the number of desks
        const offsetIndex =
          deskNumber === 3 &&
          isDrawingDeskFeature(evt.feature) &&
          evt.feature.getProperties().Index === 3
            ? 0
            : deskNumber;
        const newStoredOffsets = {
          ...storedOffsets,
          [floor.Id]: {
            ...(storedOffsets[floor.Id] || {}),
            [offsetIndex]: evt.deskOffset,
          },
        };
        setStoredOffsets(newStoredOffsets);

        reRenderDeskOutlineFeatures(beaconsLayer.selected);
      }),
    );

    olListenersKeys.push(
      rotateBeacon.on(
        // @ts-expect-error ol problems
        RotateEventType.END,
        (evt: {
          feature: Feature<Point>;
          mapBrowserEvent: MapBrowserEvent<UIEvent>;
          rotation?: number;
        }) => {
          // Save rotation in localStorage
          const { feature, rotation } = evt;
          const isNew = !feature.get('Id');
          if (isNew && rotation) {
            setStoredRotation(rotation);
          }
          resetIntersectedRooms();
          setIntersectedRooms(
            getIntersectedRooms(
              new Point(evt.feature?.getGeometry()?.getCoordinates()!),
              feature as Feature<Point>,
            ),
          );
        },
      ),
    );

    return () => {
      unByKey(olListenersKeys);
    };
  }, [storedOffsets]);

  useEffect(() => {
    // Check for floor.Id in order to have it in dependency array
    // Floors with same image but different Id will trigger this useEffect
    if (floor.Id && floor.Image) {
      baseLayer.setImage(floor.Image);
    }
    // Reset states
    setNewBeacon(undefined);
    setSelectedRoom(undefined);
    setNewBeaconGeometry(null);
    setSelectedBeacon(undefined);
    setIsAddingRoom(false);
    resetMapInteractions(map);
    drawingBeaconSource.clear();
    drawingDesksSource.clear();
    drawingRoomSource.clear();
    // Needs to have the "floor" dependency otherwise it won't update
    // after floor was once loaded and changed again
  }, [floor.Image, map, floor.Id]);

  // When clicking outside the scaling features or clicking a room -> reset
  useEffect(() => {
    const selectInteraction = select.on('select', () =>
      resetScaling(selectedRoomsAndFeatures),
    );

    return () => {
      unByKey(selectInteraction);
    };
  }, [selectedRoomsAndFeatures]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Should not update on isSaving update
  useEffect(() => {
    if (deskData?.Rooms) {
      roomLayer.setFeatures(deskData.Rooms);
      const roomLayerSource = roomLayer.olLayer.getSource() as VectorSource;

      // First only the rooms can be selected and afterwards all the other features within
      // the room are selected for moving in order to make sure, that all data is set on the layer
      map.addInteraction(dragBox);

      olListenersKeys.push(
        dragBox.on('boxstart', () => {
          // select.on('select') is not being called when holding platformModifierKey, so reset outline here
          roomOutlineSource.clear();
          map.addInteraction(select);
          setSelectedRooms(undefined);
          select.getFeatures().clear();
        }),
      );

      olListenersKeys.push(
        dragBox.on('boxend', () => {
          const boxExtent = dragBox.getGeometry().getExtent();
          const boxFeatures =
            roomLayer.features?.filter((feature) =>
              feature.getGeometry()?.intersectsExtent(boxExtent),
            ) ?? [];

          // Set the select style to all intersected features
          const selected = select.getFeatures().extend(boxFeatures);

          const rooms = selected
            .getArray()
            .filter((f): f is Feature<Polygon> => {
              const geometry = f.getGeometry();
              if (geometry) {
                return isPolygon(geometry);
              }
              return false;
            });

          setSelectedRooms(rooms.length ? new Collection(rooms) : undefined);
          createRoomOutlineFeats(rooms);
        }),
      );

      if (room) {
        const highlightedRoom = (
          roomLayerSource.getFeatures() as DeskFeatures[]
        ).find((r) => r.getProperties().Name === room) as
          | RoomFeatures
          | undefined;

        roomLayer.highlighted = highlightedRoom;
      }

      roomLayerSource.changed();

      // If there is a selected Room, set it again to have up to date values
      const selectedRoomId = selectedRoom?.getProperties()?.Id;
      if (selectedRoomId) {
        const newSelectedRoom = roomLayerSource
          .getFeatures()
          .find((room) => room.getProperties().Id === selectedRoomId) as
          | RoomFeatures
          | undefined;
        if (newSelectedRoom) {
          setSelectedRoom(newSelectedRoom);
        }
      }
      // Only when there is new data set disable loading indicator
      if (isSaving) {
        setIsSaving(false);
      }

      return () => {
        unByKey(olListenersKeys);
      };
    }
  }, [deskData?.Rooms, room]);

  // Effect to populate the beacon Layer.
  // biome-ignore lint/correctness/useExhaustiveDependencies: Should not update on isSaving update
  useEffect(() => {
    if (deskData?.Desks) {
      // Set radius that appears most often as fallback radius.
      setFallbackRadius(mode(deskData.Desks.map((d) => d.Radius)));

      desksLayer.setFeatures(deskData.Desks);
      // Only when there is new data set disable loading indicator
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [deskData?.Desks]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Should not update on isSaving update
  useEffect(() => {
    if (deskData?.MqttBeacons) {
      beaconsLayer.setFeatures(deskData.MqttBeacons);
      const deskFeats = (
        desksLayer.olLayer.getSource() as VectorSource<Feature<Point>>
      ).getFeatures() as DeskFeatures[];

      if (beacon) {
        const highlighted = (
          (
            beaconsLayer.olLayer.getSource() as VectorSource
          ).getFeatures() as BeaconFeatures[]
        ).find((b) => b.getProperties().Name === beacon);

        beaconsLayer.highlighted = highlighted;
      }

      const currentFallbackOffsets = {
        '0': [] as number[][],
        '1': [] as number[][],
        '2': [] as number[][],
        '3': [] as number[][],
        '4': [] as number[][],
      } as FallbackOffsets;
      for (const beacon of deskData.MqttBeacons) {
        const beaconDeskFeats = deskFeats.filter(
          (d) => d.getProperties().Sensor,
        );
        const currentIndex = 1;
        const [deskX, deskY] =
          beaconDeskFeats
            .find((d) => d.getProperties().Sensor)
            ?.getGeometry()
            ?.getCoordinates() || [];
        const [beaconX, beaconY] = beacon.Geometry.coordinates;

        if (beaconDeskFeats.length === 3) {
          const thirdIndexCoords = beaconDeskFeats
            .find((d) => d.getProperties().Sensor)
            ?.getGeometry()
            ?.getCoordinates();
          if (
            typeof beaconX === 'number' &&
            typeof beaconY === 'number' &&
            thirdIndexCoords &&
            typeof thirdIndexCoords[0] === 'number' &&
            typeof thirdIndexCoords[1] === 'number'
          ) {
            const newOffest = getOffset(
              beaconDeskFeats,
              3,
              [beaconX, beaconY],
              [thirdIndexCoords[0], thirdIndexCoords[1]],
            );
            if (newOffest) {
              currentFallbackOffsets['0']?.push(newOffest);
            }
          }
        }

        if (
          typeof beaconX === 'number' &&
          typeof beaconY === 'number' &&
          typeof deskX === 'number' &&
          typeof deskY === 'number'
        ) {
          const newOffest = getOffset(
            beaconDeskFeats,
            currentIndex,
            [beaconX, beaconY],
            [deskX, deskY],
          );
          const deskLength = beaconDeskFeats.length;
          if (newOffest && [0, 1, 2, 3, 4].includes(deskLength)) {
            currentFallbackOffsets[deskLength as keyof FallbackOffsets]?.push(
              newOffest,
            );
          }
        }
      }
      const newFallbackOffsets = {} as FloorStoredOffsets;
      // Filter only the most often values for each Head number as fallback value
      for (const [key, value] of Object.entries(currentFallbackOffsets)) {
        newFallbackOffsets[
          Number.parseInt(key, 10) as keyof FloorStoredOffsets
        ] = coordinatesMode(value);
      }
      setFallbackOffsets(newFallbackOffsets);
      // Only when there is new data set disable loading indicator
      if (isSaving) {
        setIsSaving(false);
      }
    }
  }, [deskData?.MqttBeacons]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Should not update on isSaving update
  useEffect(() => {
    if (deskData?.smartModules) {
      beaconsModuleLayer.setFeatures(deskData.smartModules);
      if (isSavingModule) {
        setIsSavingModule(false);
      }
    }
  }, [deskData?.smartModules]);

  const isDeskBeacon = useMemo(
    () =>
      !!(
        (!hasModuleSensors(newBeacon) && hasDeskInUseSensors(newBeacon)) ||
        (!hasModuleSensors(selectedBeacon as BeaconFeatures) &&
          hasDeskInUseSensors(selectedBeacon as BeaconFeatures))
      ),
    [newBeacon, selectedBeacon],
  );

  // Effect to handle room selection
  // biome-ignore lint/correctness/useExhaustiveDependencies: no (map, interactions, selectedBeacon)
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.altKey) {
        for (const feature of modifyRoomSource.getFeatures()) {
          feature.setStyle(
            new Style({
              image: new Icon({
                src: hiX,
              }),
              geometry: (feature) => {
                // Return the coordinates of the first ring of the polygon
                const coordinates = (feature as RoomFeatures)
                  .getGeometry()
                  ?.getCoordinates()[0];

                return new MultiPoint(coordinates ?? [0, 0]);
              },
            }),
          );
        }
      }
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        for (const feature of modifyRoomSource.getFeatures()) {
          feature.setStyle(undefined);
        }
      }
    };

    if (!selectedRoom) {
      resetMapInteractions(map);
      modifyRoomSource.clear();
      roomLayer.selected = null;
      roomLayer.hidden = null;
    } else if (selectedBeacon) {
      resetMapInteractions(map);
      modifyRoomSource.clear();
      roomLayer.selected = selectedRoom;
    } else {
      roomLayer.hidden = selectedRoom.getProperties().Id.toString();
      modifyRoomSource.clear();
      modifyRoomSource.addFeatures([
        new Feature({
          ...selectedRoom.getProperties(),
        }),
      ]);

      // Show a delete icon when pressing 'Alt'
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);

      resetMapInteractions(map);
      map.addInteraction(roomModify);
    }
    roomLayer.olLayer.changed();

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [selectedRoom]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't need in deps array (map, interactions)
  useEffect(() => {
    if (isAddingRoom) {
      resetMapInteractions(map);
      drawRoom.setMapExtent(map.getView().getProjection().getExtent());
      snapLineForRoomSource.clear();
      for (const int of [drawRoom, snapLineForRoomInteraction]) {
        map.addInteraction(int);
      }
    } else {
      // Clean up intern variable on drawRoom interaction
      drawRoom.cleanUpVariables();
      resetMapInteractions(map);
      setNewRoomGeom(null);
    }
    return () => {
      resetMapInteractions(map);
      setNewRoomGeom(null);
      drawingRoomSource.clear();
    };
  }, [isAddingRoom]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't need in deps array (map, interactions)
  useEffect(() => {
    resetMapInteractions(map);
    if (activateMeasureDistanceInteraction) {
      unByKey(olListenersKeys);
      olListenersKeys.push(
        // Clear the source on draw end to keep only last drawing
        drawMeasureDistanceLine.on('drawend', () =>
          drawMeasureDistanceSource.clear(),
        ),
      );

      map.addInteraction(drawMeasureDistanceLine);
    }

    return () => {
      unByKey(olListenersKeys);
      resetMapInteractions(map);
      drawMeasureDistanceSource.clear();
    };
  }, [activateMeasureDistanceInteraction]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Effect to unselect beacon layer when changes comes from react states
  useEffect(() => {
    deskOutlineSource.clear();
    rotateAnchorSource.clear();
    drawingBeaconSource.clear();
    drawingDesksSource.clear();
    if (!selectedBeacon) {
      // Remove selected desk layer style
      beaconsLayer.selected = null;
      beaconsModuleLayer.selected = null;
      desksLayer.selectedFeatureId = undefined;
      snapLineForBeaconLayer.selectedBeaconId = null;
    } else {
      desksLayer.selectedFeatureId = selectedBeacon.getProperties().Id;
      if (deskData) {
        // Reset snapLines without currently selected beacon
        const filteredLines = getFilteredSnapLines(
          map.getView().getProjection().getExtent(),
          deskData,
          selectedBeacon,
        );
        snapLineForBeaconSource.clear();
        snapLineForBeaconSource.addFeatures(filteredLines);
      }
      if (isDeskBeacon) {
        beaconsModuleLayer.selected = null;
        beaconsLayer.selected = selectedBeacon;
        // Draw initial outline
        const beaconSensorIds = selectedBeacon
          .getProperties()
          .Sensors.map((s) => s.Id);
        const deskFeats = (
          ((desksLayer.olLayer as OLVectorLayer<VectorSource<Feature<Point>>>)
            .getSource()
            ?.getFeatures() ?? []) as DeskFeatures[]
        ).filter((d) => {
          const sensor = d.getProperties().Sensor;
          return sensor?.Id && beaconSensorIds.includes(sensor.Id);
        });
        createDeskOutlineFeats(selectedBeacon as BeaconFeatures, deskFeats);
      } else {
        beaconsLayer.selected = null;
        deskOutlineSource.clear();
        rotateAnchorSource.clear();
        beaconsModuleLayer.selected = selectedBeacon;
      }
    }
    // Force Redraw layers
    beaconsLayer.olLayer.changed();
    snapLineForBeaconLayer.olLayer.changed();
    beaconsModuleLayer.olLayer.changed();
    desksLayer.olLayer.changed();
  }, [selectedBeacon]);

  useEffect(() => {
    if (isSelectingNewBeacon) {
      // clear drawing layers
      drawingBeaconSource.clear();
      drawingDesksSource.clear();
    }
  }, [isSelectingNewBeacon]);

  // Effect to handle the light drawing interation
  // biome-ignore lint/correctness/useExhaustiveDependencies: don't need in deps array (map, interactions)
  useEffect(() => {
    if (newBeacon) {
      (
        drawingBeaconLayer.olLayer as OLVectorLayer<
          VectorSource<Feature<Point>>
        >
      ).setStyle(beaconStyle);
      const drawBeaconGeometry = new Draw({
        source: drawingBeaconSource,
        style: beaconStyle,
        type: 'Point',
        condition: (e) => {
          // overwrite default noModifierKeys to allow drawing while holding platform-modifier-key (Ctrl or Cmd)
          return !(e.originalEvent.altKey || e.originalEvent.shiftKey);
        },
      });
      unByKey(olListenersKeys);

      olListenersKeys.push(
        drawBeaconGeometry.on('drawend', (evt) => {
          drawingBeaconSource.clear();
          const newFeat = evt.feature as Feature<Point>;
          // Pass sensors info to drawn feature.
          newFeat.set('Sensors', newBeacon.getProperties().Sensors);
          const newGeom = newFeat.getGeometry() as Point;
          setNewBeaconGeometry(newGeom);
        }),
      );
      resetMapInteractions(map);
      map.addInteraction(drawBeaconGeometry);
      // Needed as otherwise snap is not working for new beacons
      map.addInteraction(snapLineForBeaconInteraction);
    } else {
      resetMapInteractions(map);
      setNewBeaconGeometry(null);
      deskOutlineSource.clear();
      rotateAnchorSource.clear();
      drawingBeaconSource.clear();
      drawingDesksSource.clear();
    }
  }, [newBeacon]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Effect to handle the desks drawing interation, when the beacon is already positionned.
  useEffect(() => {
    if (isDeskBeacon) {
      if (newBeaconGeometry) {
        let initDeskFeats = [] as Feature<Point>[];
        // Add features to draw desk layers
        if (newBeacon) {
          const deskInUseSensors = newBeacon
            .getProperties()
            .Sensors.filter((s) => s.SensorType.Name === OtherType.DESKINUSE);
          initDeskFeats = getInitialDesksFeats(
            deskInUseSensors,
            deskInUseSensors.length,
            floor.Id,
            () => true,
            fallbackRadius,
            fallbackOffsets,
            newBeaconGeometry,
            true,
          );
        } else if (selectedBeacon) {
          const beaconSensors = selectedBeacon.getProperties().Sensors;
          const beaconSensorIds = beaconSensors?.map((s) => s.Id);
          const existingDeskFeatures =
            deskData?.Desks.filter((d) =>
              beaconSensorIds?.includes(d.Sensor?.Id ?? 0),
            ).map(
              (d) =>
                new Feature({
                  __typename: 'DrawingDesks',
                  geometry: new Point(d.Geometry.coordinates as number[]),
                  Radius: d.Radius,
                  DeskId: d.Id,
                  Id: d.Sensor?.Id,
                  Index: d.Sensor?.Index,
                  visible: true,
                }),
            ) ?? [];
          initDeskFeats = existingDeskFeatures;
        }
        drawingDesksSource.clear();
        if (initDeskFeats.length) {
          drawingDesksSource.addFeatures(initDeskFeats);
        }
        if (newBeacon) {
          const [drawingBeaconFeat] =
            drawingBeaconSource.getFeatures() as BeaconFeatures[];
          if (drawingBeaconFeat) {
            beaconsLayer.selected = drawingBeaconFeat;
            const drawingDeskFeats = ((
              drawingDesksLayer.olLayer as OLVectorLayer<
                VectorSource<Feature<Point>>
              >
            )
              .getSource()
              ?.getFeatures() ?? []) as DrawingDeskFeatures[];
            createDeskOutlineFeats(drawingBeaconFeat, drawingDeskFeats);

            // rotate the beacon if a value is in localstorage
            const localstorageRotation = Number.parseFloat(
              localStorage.getItem(`${LS_DESK_ROTATION}-${floor.Id}`) ?? '0',
            );
            if (localstorageRotation) {
              newBeacon.set('rotation', localstorageRotation);
              drawingBeaconFeat.set('rotation', localstorageRotation);
            }
          }
        }
        resetMapInteractions(map);

        const newInteractions = [
          deskMove,
          ...beaconMoveInteractions,
          rotateBeacon,
        ];

        // Add ability to scale, drag, and rotate, modify (ensure rectangle shape and correct angles).
        for (const interaction of newInteractions) {
          map.addInteraction(interaction);
        }
      } else {
        resetMapInteractions(map);
        if (isSaving) {
          // Ensure to save the modified geometry and avoid the feature to jump back on save
          for (const drawnDesk of drawingDesksSource.getFeatures() as DrawingDeskFeatures[]) {
            const deskFeat = (
              (
                desksLayer.olLayer.getSource() as VectorSource<Feature<Point>>
              ).getFeatures() as DeskFeatures[]
            ).find(
              (f) => f.getProperties().Id === drawnDesk.getProperties().DeskId,
            );
            if (deskFeat) {
              deskFeat.setGeometry(drawnDesk.getGeometry());
            }
          }
        }
        drawingDesksSource.clear();
        deskOutlineSource.clear();
        rotateAnchorSource.clear();
      }
    } else {
      // For module beacons reset interactions
      resetMapInteractions(map);

      if (newBeaconGeometry) {
        for (const int of beaconMoveInteractions) {
          map.addInteraction(int);
        }
      }
    }
  }, [newBeaconGeometry]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    resetIntersectedRooms();
    if (newBeaconGeometry && (newBeacon || selectedBeacon)) {
      setIntersectedRooms(
        getIntersectedRooms(
          newBeaconGeometry,
          (newBeacon || selectedBeacon) as Feature<Point>,
        ),
      );
    } else {
      setIntersectedRooms([]);
    }
  }, [newBeaconGeometry]);

  useEffect(() => {
    rotateBeacon.set('floorId', floor.Id);
  }, [floor]);

  useEffect(() => {
    intersectedRooms?.map((f) => f.set(INTERSECTED_PARAM, true));
    roomLayer.olLayer.changed();
  }, [intersectedRooms]);

  const addDeviceCardVisible = useMemo(
    () =>
      !!(newBeacon || selectedBeacon) && !activateMeasureDistanceInteraction,
    [newBeacon, selectedBeacon, activateMeasureDistanceInteraction],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Should store offset when opening a card
  useEffect(() => {
    setStoredOffsets(
      localStorage.getItem(LS_DESK_OFFSETS) &&
        localStorage.getItem(LS_DESK_OFFSETS) !== 'undefined'
        ? JSON.parse(localStorage.getItem(LS_DESK_OFFSETS) || '{}')
        : {},
    );
  }, [addDeviceCardVisible]);

  useEffect(() => {
    beaconsLayer.setShowLabel(showBeaconLabels);
    beaconsLayer.olLayer.changed();
    beaconsModuleLayer.setShowLabel(showBeaconLabels);
    beaconsModuleLayer.olLayer.changed();
  }, [showBeaconLabels]);

  useEffect(() => {
    desksLayer.showLabel = showDeskIndexes;
    desksLayer.olLayer.changed();
  }, [showDeskIndexes]);

  const onModuleClose = (saveModule: boolean) => {
    // Reset beacon geometry
    if (!saveModule && selectedBeacon) {
      resetFeatureGeometry(selectedBeacon);
    }
    resetBeaconAndRoom();
  };

  const onDeskClose = (isSavingDesk: boolean) => {
    // Reset beacon geometry
    if (!isSavingDesk && selectedBeacon) {
      resetFeatureGeometry(selectedBeacon);
    }
    resetBeaconAndRoom();
  };

  const onRoomClose = (isSavingRoom: boolean) => {
    if (!isSavingRoom) {
      resetBeaconAndRoomGeometry();
    }

    setIsAddingRoom(false);
    setSelectedRoom(undefined);
    roomLayer.olLayer.changed();
  };

  const onGeometryClose = (isSavingGeometry: boolean) => {
    if (!isSavingGeometry) {
      for (const feature of selectedRoomsAndFeatures) {
        resetFeatureGeometry(feature);
      }
    }

    resetMapInteractions(map);
    resetBeaconAndRoom();
    roomLayer.olLayer.changed();
    desksLayer.olLayer.changed();
    beaconsLayer.olLayer.changed();
    beaconsModuleLayer.olLayer.changed();
    map.removeInteraction(select);
    resetScaling(selectedRoomsAndFeatures);
  };

  const resetBeaconAndRoom = () => {
    setSelectedRoom(undefined);
    setNewBeacon(undefined);
    setSelectedBeacon(undefined);
    setNewBeaconGeometry(null);
    desksLayer.olLayer.changed();
  };

  // Hide scale and move features
  const resetScaling = (selectedFeatures: FloorRoomMapFeatures[]) => {
    for (const feature of selectedFeatures) {
      resetFeatureGeometry(feature);
    }
    roomOutlineSource.clear();
    map.removeInteraction(select);
    setSelectedRooms(undefined);
    setMoveAndScaleButtonActive(false);
    unByKey(olListenersKeys);
  };

  const resetBeaconAndRoomGeometry = () => {
    if (selectedRoom) {
      const roomGeometry = selectedRoom.getGeometry();

      if (roomGeometry) {
        const intersectedItems = getIntersectedElements(
          roomGeometry,
          selectedRoomsAndFeatures,
        );

        // Set coordinates after getting the intersected items as otherwise it won't be intersected anymore
        resetFeatureGeometry(selectedRoom);

        for (const item of intersectedItems) {
          resetFeatureGeometry(item);
        }
      }
    }
    resetBeaconAndRoom();
    setNewRoomGeom(null);
  };

  const onCloseDrawMeasureDistanceCard = () => {
    drawMeasureDistanceSource.clear();
    setActivateMeasureDistanceInteraction(false);
  };

  const switches: SwitchInterface[] = [
    {
      name: 'Beacon labels',
      isEnabled: showBeaconLabels,
      onSetEnable: (enabled) => {
        setShowBeaconLabels(enabled);
        localStorage.setItem(LS_SHOW_BEACON_LABEL, JSON.stringify(enabled));
      },
      image: (
        <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full">
          <FaAudioDescription className="mx-auto size-4 text-primary-700" />
        </div>
      ),
    },
    {
      name: 'Desk indexes',
      isEnabled: showDeskIndexes,
      onSetEnable: (enabled: boolean) => {
        setShowDeskIndexes(enabled);
        localStorage.setItem(LS_SHOW_DESK_INDEX, JSON.stringify(enabled));
      },
      image: (
        <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full">
          <HiInformationCircle className="mx-auto size-5 text-primary-700" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Map<FloorRoomMapFeatures>
        map={map}
        additionalActions={
          <div className="flex flex-col space-y-2">
            <Transition show={!!selectedRooms}>
              <PrivateWrapper roleRequired={HasuraPermissions.WRITE_DESK}>
                <MapActionButtons
                  olListenersKeys={olListenersKeys}
                  onGeometryClose={onGeometryClose}
                  setIsSaving={setIsSaving}
                  selectedRoomsAndFeatures={selectedRoomsAndFeatures}
                  moveAndScaleButtonActive={moveAndScaleButtonActive}
                  setMoveAndScaleButtonActive={setMoveAndScaleButtonActive}
                  map={map}
                  setSelectedRoomsAndFeatures={setSelectedRoomsAndFeatures}
                  selectedRooms={selectedRooms}
                />
              </PrivateWrapper>
            </Transition>
            <PrivateWrapper roleRequired={HasuraPermissions.WRITE_ROOM}>
              <CanvasButton
                data-test-id="add-room-button"
                tooltip={<FormattedMessage id="Add Room" />}
                active={isAddingRoom}
                disabled={!deskData || loadingDesk}
                onClick={() => {
                  resetBeaconAndRoomGeometry();
                  setIsAddingRoom(!isAddingRoom);
                }}
              >
                <HiOutlineCube className="size-5" />
              </CanvasButton>
            </PrivateWrapper>

            <PrivateWrapper roleRequired={HasuraPermissions.WRITE_DESK}>
              <HelpTooltip
                stepName="clickedAddDevice"
                content={
                  <CanvasButton
                    tooltip={<FormattedMessage id="Add Device" />}
                    data-test-id="add-device"
                    disabled={!deskData || loadingDesk}
                    active={isSelectingNewBeacon}
                    onClick={() => {
                      setInitialSetupSteps('clickedAddDevice');
                      // Reset beacon geometry
                      if (selectedBeacon) {
                        resetFeatureGeometry(selectedBeacon);
                      }
                      resetBeaconAndRoom();
                      setIsSelectingNewBeacon(!isSelectingNewBeacon);
                      // Deselect the room button and being able to draw a room
                      setIsAddingRoom(false);
                    }}
                  >
                    <HiOutlineServerStack className="size-5" />
                  </CanvasButton>
                }
              >
                <FormattedMessage id="Initial: Add device" />
              </HelpTooltip>
            </PrivateWrapper>
          </div>
        }
        enableCanvasExport
        canvasExportName={`${buildingName || ''}-${floor.Number}`}
        layers={mapLayers}
        isLoadingFeatures={loadingDesk || isSaving || isSavingModule}
        onFeaturesClick={(features) => {
          const mapTarget = map.getTarget();
          // Don't allow interaction on canvas export map
          if (
            mapTarget instanceof HTMLElement &&
            mapTarget.id === CANVAS_EXPORT_MAP_ID
          ) {
            return;
          }

          // sort to ensure desk are first to be clicked
          const sortedFeats = features
            .filter(
              (f): f is BeaconFeatures | RoomFeatures | DeskFeatures =>
                !isSnap(f),
            )
            .sort((a, b) =>
              (a.getProperties().__typename ?? '') >
              (b.getProperties().__typename ?? '')
                ? 1
                : -1,
            );
          onFeaturesClick(sortedFeats);
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const layers = hoveredFeatures.map((c) => c.layer);
          const features = hoveredFeatures.map((hF) => hF.feature);

          if (features.length) {
            const sortedFeats = features
              .filter(
                (f): f is BeaconFeatures | RoomFeatures | DeskFeatures =>
                  !isSnap(f),
              )
              .sort((a, b) =>
                (a.getProperties().__typename ?? '') >
                (b.getProperties().__typename ?? '')
                  ? 1
                  : -1,
              );
            if (sortedFeats[0]) {
              setHoveredFeature(sortedFeats[0]);
            }
          } else {
            setHoveredFeature(null);
          }

          evt.map.getTargetElement().style.cursor = hoveredFeatures.length
            ? getCursor(layers)
            : '';
        }}
        renderTooltip={(props) => {
          if (
            !(
              hoveredFeature?.getProperties().Name &&
              !newBeacon &&
              !newBeaconGeometry
            )
          ) {
            return undefined;
          }

          return (
            <Tooltip {...props}>{hoveredFeature.getProperties().Name}</Tooltip>
          );
        }}
      />
      <Panel open={panelOpen} setOpen={setPanelOpen}>
        <Panel.Content title={<FormattedMessage id="Settings" />}>
          <div className="flex flex-col gap-2">
            <LayerInfoTogglers switches={switches} />
          </div>
        </Panel.Content>
      </Panel>
      <PrivateWrapper roleRequired={HasuraPermissions.WRITE_ROOM}>
        <AddRoomCard
          open={isAddingRoom || !!selectedRoom}
          floor={floor}
          geometry={newRoomGeom}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          setIsAddingRoom={setIsAddingRoom}
          setIsSaving={setIsSaving}
          map={map}
          onClose={onRoomClose}
          activateMeasureDistanceInteraction={
            activateMeasureDistanceInteraction
          }
          setActivateMeasureDistanceInteraction={
            setActivateMeasureDistanceInteraction
          }
        />
      </PrivateWrapper>
      <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_ROOMMODES}>
        <MeasureDistanceCard
          floor={floor}
          open={activateMeasureDistanceInteraction}
          lineGeometry={
            drawMeasureDistanceSource
              .getFeatures()[0]
              ?.getGeometry() as LineString
          }
          onClose={onCloseDrawMeasureDistanceCard}
        />
      </PrivateWrapper>
      <SelectNewBeaconCard
        open={isSelectingNewBeacon}
        setOpen={setIsSelectingNewBeacon}
        onBeaconSelect={(beacon) => {
          setIsSelectingNewBeacon(false);
          const typedBeacon = new TypedFeature<
            FloorMapFeaturesQuery['MqttBeacons'][number],
            Point
          >(beacon);
          setNewBeacon(typedBeacon);

          // Set the new beacon as selected in order to make snap working
          setSelectedBeacon(typedBeacon);
        }}
      />

      <PrivateWrapper roleRequired={HasuraPermissions.WRITE_DESK}>
        <AddDesksCard
          map={map}
          open={addDeviceCardVisible && isDeskBeacon}
          floorId={floor.Id}
          isNew={!!newBeacon}
          features={deskData?.Desks}
          drawingDesksLayer={drawingDesksLayer}
          beacon={(selectedBeacon || newBeacon) as BeaconFeatures}
          beaconGeom={newBeaconGeometry}
          intersectedRooms={intersectedRooms}
          setIsSelectingNewBeacon={setIsSelectingNewBeacon}
          storedRotation={storedRotation}
          storedOffsets={storedOffsets}
          setIsSaving={setIsSaving}
          onClose={onDeskClose}
          onRadiusChange={() =>
            reRenderDeskOutlineFeatures(beaconsLayer.selected)
          }
          onRotate={(
            radians: number,
            deskFeats: (DrawingDeskFeatures | DeskFeatures)[],
          ) => {
            // Get current feature rotation and add new radians
            let beaconFeat: BeaconFeatures | undefined;
            let newRotation = 0;
            if (beaconsLayer.selected) {
              beaconFeat = beaconsLayer.selected;
              const coordinates = beaconFeat?.getGeometry()?.getCoordinates();
              newRotation = coordinates
                ? (getDeskRotation(coordinates, deskFeats) ?? 0)
                : 0;
            } else {
              [beaconFeat] =
                drawingBeaconSource.getFeatures() as BeaconFeatures[];
              newRotation = (beaconFeat?.get('rotation') ?? 0) - radians;
            }
            if (typeof newRotation === 'number') {
              newBeacon?.set('rotation', newRotation);
              beaconFeat?.set('rotation', newRotation);
              setStoredRotation((90 - newRotation) * (Math.PI / 180));
            }
            if (beaconFeat) reRenderDeskOutlineFeatures(beaconFeat);
          }}
          resetDesksToInitialPositions={resetDesksToInitialPositions}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.WRITE_DESK}>
        <AddModuleCard
          map={map}
          open={addDeviceCardVisible && !isDeskBeacon}
          isNew={!!newBeacon}
          beacon={(selectedBeacon || newBeacon) as SmartModuleFeatures}
          beaconGeom={newBeaconGeometry}
          roomLayer={roomLayer}
          intersectedRooms={intersectedRooms}
          setIsSaving={setIsSavingModule}
          setIsSelectingNewBeacon={setIsSelectingNewBeacon}
          onClose={onModuleClose}
          floorId={floor.Id}
        />
      </PrivateWrapper>

      <PrivateBeaconModal
        open={openPrivateBeaconWarning}
        onConfirm={() => setOpenPrivateBeaconWarning(false)}
        onCancel={() => {
          resetBeaconAndDeskGeometry();
          setOpenPrivateBeaconWarning(false);
        }}
      />
    </div>
  );
}
