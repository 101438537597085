import Accordion from 'generic/components/Accordion';
import TimeLineEntry, { type TimelineEntry } from './components/TimeLineEntry';
import TimeLineTitle from './components/TimeLineTitle';

interface TimeLineProps {
  loading: boolean;
  data: {
    now: boolean;
    title: string;
    entries: TimelineEntry[];
  }[];
  setSelectedPosition: (selectedPosition: number | undefined) => void;
}

export default function TimeLine({
  data,
  setSelectedPosition,
  loading,
}: TimeLineProps) {
  return data.map(({ title, entries, now }) => (
    <div
      key={title}
      className="p-5 mb-4 border border-neutral-100 rounded-lg bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-700"
    >
      {loading ? (
        <div className="w-36 h-6 animate-pulse bg-neutral-50 dark:bg-neutral-700" />
      ) : (
        <Accordion
          initialStateOpen={now}
          sticky
          title={<TimeLineTitle>{title}</TimeLineTitle>}
        >
          <ol
            className="mt-3 divide-y divider-neutral-200 dark:divide-neutral-700"
            data-test-id={title}
          >
            {loading ? (
              <div className="items-center block p-3 sm:flex hover:bg-neutral-100 dark:hover:bg-neutral-700">
                <div className="animate-pulse bg-neutral-50 dark:bg-neutral-700 size-12 mb-3 me-3 rounded-full sm:mb-0" />
                <div className="space-y-2">
                  <div className="animate-pulse bg-neutral-50 dark:bg-neutral-700 h-8 w-36" />
                  <div className="animate-pulse bg-neutral-50 dark:bg-neutral-700 h-5 w-24" />
                </div>
              </div>
            ) : (
              entries.map(({ id, title, subtitle, icon, active }) => (
                <TimeLineEntry
                  key={id}
                  icon={icon}
                  title={title}
                  active={active}
                  subtitle={subtitle}
                  onMouseEnter={() => setSelectedPosition(id)}
                  onMouseLeave={() => setSelectedPosition(undefined)}
                />
              ))
            )}
          </ol>
        </Accordion>
      )}
    </div>
  ));
}
