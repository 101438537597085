import type { Colors, JsonGeometry } from 'common/types';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type { LineString, Point, Polygon } from 'ol/geom';
import type Geometry from 'ol/geom/Geometry';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import getColor from 'utils/getColor';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export function isPoint(geometry: Geometry): geometry is Point {
  if ((geometry as Point).getType() === 'Point') {
    return true;
  }
  return false;
}

export function isPolygon(geometry: Geometry): geometry is Polygon {
  if ((geometry as Polygon).getType() === 'Polygon') {
    return true;
  }
  return false;
}

export function isLineString(geometry: Geometry): geometry is LineString {
  if ((geometry as Polygon).getType() === 'LineString') {
    return true;
  }
  return false;
}

interface GeometryLayerInterface {
  Geometry: JsonGeometry;
}

type StyleFunction<T> = (
  feat: TypedFeature<T, Geometry>,
  resolution: number,
  hoveredFeature?: TypedFeature<T, Geometry>,
  showLabel?: boolean,
  selectedFeatureId?: number,
) => Style[] | undefined;

function styleFunction<T>(
  feat: TypedFeature<T, Geometry>,
  color: keyof typeof Colors,
  selectedFeature?: TypedFeature<T, Geometry>,
) {
  return isPoint(feat.getGeometry()!)
    ? // Default Point style
      [
        new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: getColor(
                color,
                feat.getGeometry() === selectedFeature?.getGeometry()
                  ? '.4'
                  : '.9',
              ),
            }),
            stroke: new Stroke({
              color: getColor(
                color,
                feat.getGeometry() === selectedFeature?.getGeometry()
                  ? '.4'
                  : '1',
              ),
              width:
                feat.getGeometry() === selectedFeature?.getGeometry() ? 3 : 2,
            }),
          }),
        }),
      ]
    : // Default Polygon style
      [
        new Style({
          stroke: new Stroke({
            color: getColor(
              color,
              feat.getGeometry() === selectedFeature?.getGeometry()
                ? '.9'
                : '.8',
            ),
            width: 2,
          }),
          fill: new Fill({
            color: getColor(
              color,
              feat.getGeometry() === selectedFeature?.getGeometry()
                ? '.9'
                : '.75',
            ),
          }),
        }),
      ];
}

interface GeometryLayerOptions<T> extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Geometry>>>;
  styleFunction?: StyleFunction<T>;
}

interface GeometryOptions<T> {
  style?: StyleFunction<T>;
  name?: string;
}

class GeometryLayer<
  T extends GeometryLayerInterface,
> extends VectorLayer<Geometry> {
  features?: Feature<Geometry>[];

  hoveredFeature?: TypedFeature<T, Geometry>;

  color: keyof typeof Colors = 'GREEN';

  selectedFeatureId?: number;

  showLabel?: boolean;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor(options?: GeometryOptions<T>) {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature, resolution) =>
            options?.style
              ? options.style(
                  feature as TypedFeature<T, Geometry>,
                  resolution,
                  this.hoveredFeature,
                  this.showLabel,
                  this.selectedFeatureId,
                )
              : (styleFunction<T>(
                  feature as TypedFeature<T, Geometry>,
                  this.color,
                  this.hoveredFeature,
                ) as any),
        }),
      },
      name: options?.name,
    } as GeometryLayerOptions<T>);
  }

  setFeatures(feats: T[]): void {
    const source = this.olLayer.getSource() as VectorSource<Feature<Geometry>>;
    const newFeatures = new GeoJSON().readFeatures(this.toGeoJson(feats));
    this.features = newFeatures;
    source.clear();
    source.addFeatures(newFeatures);
  }

  toGeoJson(feats?: T[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geom } = feat;
      return {
        type: 'Feature',
        geometry: geom,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default GeometryLayer;
