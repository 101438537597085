import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { Action } from 'mda2-frontend/src/common/types';
import { cloneElement } from 'react';
import {
  HiOutlineCheckBadge,
  HiOutlineExclamationCircle,
  HiOutlinePencil,
} from 'react-icons/hi2';
import Transition from '../Transition';

interface ModalProps {
  open?: boolean;
  children: React.ReactNode;
  title?: string | JSX.Element;
  footer?: React.ReactNode;
  setShowModal: (arg: boolean) => void;
  action?: Action;
  icon?: JSX.Element;
  wrapperClassName?: string;
  className?: string;
}

export default function Modal({
  title,
  children,
  open,
  setShowModal,
  footer,
  action,
  icon,
  wrapperClassName = '',
  className = '',
}: ModalProps) {
  return (
    <Dialog
      data-test-id="modal"
      className="relative z-40"
      open={open}
      onClose={setShowModal}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/60 duration-300 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 w-screen overflow-y-auto p-4">
        <div className="flex min-h-full items-center justify-center">
          <DialogPanel
            transition
            className={`inline-block backdrop-blur align-bottom dark:text-white text-left transition-all w-full sm:my-8 sm:align-middle sm:w-full sm:max-w-lg duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 ${wrapperClassName}`}
          >
            <div className="flex items-end justify-center h-full text-center sm:block shadow-xl">
              <div
                className={`relative h-full z-10 bg-opacity-90 dark:bg-opacity-50 bg-white dark:bg-neutral-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-2xl ${
                  !footer ? 'rounded-b-2xl md:rounded-b-lg' : 'border-b-0'
                } md:rounded-t-lg ${className}`}
              >
                <div className="space-x-2 sm:flex sm:items-start overflow-y-auto md:overflow-y-visible max-h-96 sm:max-h-full">
                  <Transition show={!!action}>
                    <div
                      className={`${
                        action === Action.REMOVE ? 'bg-red-100' : ''
                      } ${action === Action.UPDATE ? 'bg-blue-100' : ''} ${
                        action === Action.ADD ? 'bg-green-100' : ''
                      } mx-auto flex-shrink-0 flex items-center justify-center rounded-full sm:mx-0 size-12 sm:size-10`}
                    >
                      {icon &&
                        cloneElement(icon, {
                          className: `size-6 ${icon.props.className} ${action === Action.REMOVE ? 'text-red-500' : ''} ${action === Action.UPDATE ? 'text-blue-500' : ''} ${action === Action.ADD ? 'text-green-500' : ''}`,
                        })}
                      {!icon && action === Action.REMOVE && (
                        <HiOutlineExclamationCircle
                          className="size-6 text-red-500"
                          aria-hidden="true"
                        />
                      )}
                      {!icon && action === Action.UPDATE && (
                        <HiOutlinePencil
                          className="size-6 text-blue-500"
                          aria-hidden="true"
                        />
                      )}
                      {!icon && action === Action.ADD && (
                        <HiOutlineCheckBadge
                          className="size-6 text-green-500"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </Transition>

                  <div
                    className={`mt-3 w-full text-center sm:mt-0 ${
                      action ? 'sm:ml-4' : ''
                    } sm:text-left`}
                  >
                    {title && (
                      <DialogTitle className="font-bold">{title}</DialogTitle>
                    )}

                    <div className={action ? 'mt-2' : ''}>
                      <div className="text-sm text-neutral-500 dark:text-neutral-200">
                        <div>{children}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {footer && (
                <div className="relative bg-neutral-50 dark:bg-neutral-600 px-4 py-3 sm:px-6 backdrop-blur bg-opacity-90 flex flex-row-reverse md:rounded-b-lg border-t-0">
                  {footer}
                </div>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
