import type { Colors, JsonGeometry } from 'mda2-frontend/src/common/types';
import getClimateStatus, {
  ClimateStatus,
} from 'mda2-frontend/src/utils/getClimateStatus';
import getColor from 'mda2-frontend/src/utils/getColor';
import { lower, upper } from 'mda2-frontend/src/utils/numberrange';
import renderOfflineIcon from 'mda2-frontend/src/utils/renderOfflineIcon';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type ClimateRoomLayerFeature = {
  SensorValues: {
    SensorType: string;
    Value: number;
    GoodValue: string;
    AcceptableValue: string;
    PoorValue: string;
    Unit: string;
  }[];
  Room: string;
  Geometry: JsonGeometry;
  Offline: boolean;
};

export type ClimateRoomFeatureType = TypedFeature<
  ClimateRoomLayerFeature,
  Geometry
>;

const formatClimateLimits = (
  sv: ClimateRoomLayerFeature['SensorValues'][number],
) => {
  return {
    good: {
      start: lower(sv.GoodValue) ?? undefined,
      end: upper(sv.GoodValue) ?? undefined,
    },
    acceptable: {
      start: lower(sv.AcceptableValue) ?? undefined,
      end: upper(sv.AcceptableValue) ?? undefined,
    },
    poor: {
      start: lower(sv.PoorValue) ?? undefined,
      end: upper(sv.PoorValue) ?? undefined,
    },
  };
};

const getSensibleRangeColor = (
  sensorValues: ClimateRoomLayerFeature['SensorValues'],
): keyof typeof Colors => {
  // All values are in a good range
  if (
    sensorValues.every(
      (sv) =>
        getClimateStatus(sv.Value ?? 0, formatClimateLimits(sv)) ===
        ClimateStatus.GOOD,
    )
  ) {
    return 'GREEN';
  }

  if (
    sensorValues.some(
      (sv) =>
        getClimateStatus(sv.Value ?? 0, formatClimateLimits(sv)) ===
        ClimateStatus.POOR,
    )
  ) {
    return 'RED';
  }

  return 'YELLOW';
};

const styleFunction = (
  feature: ClimateRoomFeatureType,
  hoveredFeature: ClimateRoomFeatureType | undefined,
  isMobile: boolean,
  map: OLMap,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const { Offline: offline, SensorValues: sensorValues } =
    feature.getProperties();

  const getStrokeWidth = () => {
    if (isMobile && isHovered) return 3;
    if (isHovered) return 2;
    return 1;
  };

  const getRoomTransparency = () => {
    if (isMobile && isHovered) return '.9';
    if (isHovered) return '.7';
    return '.5';
  };

  return [
    new Style({
      stroke: new Stroke({
        color: offline
          ? getColor('NEUTRAL900', '.4')
          : getColor(
              getSensibleRangeColor(sensorValues),
              isHovered ? '1' : '.5',
            ),
        width: getStrokeWidth(),
        lineDash: isMobile && isHovered ? [7, 10] : undefined,
      }),
      fill: new Fill({
        color: offline
          ? getColor('NEUTRAL900', '.4')
          : getColor(
              getSensibleRangeColor(sensorValues),
              getRoomTransparency(),
            ),
      }),
    }),
    renderOfflineIcon(feature, offline, map),
  ];
};

interface ClimateRoomLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

class ClimateRoomLayer extends VectorLayer<Polygon> {
  features?: ClimateRoomLayerFeature[];

  hoveredFeature?: ClimateRoomFeatureType;

  isMobile: boolean;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(
              feature as ClimateRoomFeatureType,
              this.hoveredFeature,
              this.isMobile,
              this.map,
            ),
        }),
      },
    } as ClimateRoomLayerOptions);

    this.hoveredFeature = undefined;
    this.isMobile = false;
  }

  setFeatures(feats: ClimateRoomLayerFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      ClimateRoomLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: ClimateRoomLayerFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geo } = feat;
      return {
        type: 'Feature',
        geometry: geo,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default ClimateRoomLayer;
