import { Action, type Mda3ConfigurationType } from 'common/types';
import Accordion from 'generic/components/Accordion';
import Modal from 'generic/components/Modal';
import ModalFooter from 'generic/components/ModalFooter';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Transition from 'generic/components/Transition';
import {
  MqttSystems,
  useConfigureMqttDeviceMutation,
  useSetSceneConfigMutation,
} from 'graphql/types';
import useStore from 'model/store';
import { useMemo, useState } from 'react';
import { HiOutlineCog } from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';
import groupBy from 'utils/groupBy';
import parseBluerangeTopic from 'utils/parseBluerangeTopic';
import parseMda3Topic from 'utils/parseMda3Topic';
import BluerangeConfiguration from './components/BluerangeConfiguration';
import type { BluerangeConfigScene } from './components/BluerangeConfiguration/BluerangeConfiguration';
import {
  Mda3FirmwarePackageConfiguration,
  Mda3SensorPolicyConfiguration,
} from './components/Mda3Configuration';

export type ConfigurableBeacons = {
  topic: string;
  name: string;
  mqttSystem: MqttSystems;
  organization: string;
  id: number;
};

interface ConfigureBeaconModalProps {
  beaconsToConfigure?: ConfigurableBeacons[];
  setBeaconsToConfigure: (beaconsToConfigure?: ConfigurableBeacons[]) => void;
}

export default function ConfigureBeaconModal({
  beaconsToConfigure,
  setBeaconsToConfigure,
}: ConfigureBeaconModalProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const userRoles = useStore((state) => state.user)?.roles;
  const [mda3Configuration, setMda3Configuration] = useState(
    new Map<string, Mda3ConfigurationType>(),
  );
  const [bluerangeConfiguration, setBluerangeConfiguration] = useState<
    BluerangeConfigScene | undefined
  >(undefined);
  const [{ fetching }, sendConfiguration] = useConfigureMqttDeviceMutation();
  const [{ fetching: loadingBluerange }, setSceneConfigMutation] =
    useSetSceneConfigMutation();

  const beacons = useMemo(
    () =>
      beaconsToConfigure && groupBy(beaconsToConfigure, (i) => i.mqttSystem),
    [beaconsToConfigure],
  );

  const mda3BeaconOrganizations = useMemo(
    () => (beacons?.MDA3 ? groupBy(beacons.MDA3, (i) => i.organization) : {}),
    [beacons],
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Set scene config',
      })}
      icon={<HiOutlineCog />}
      action={Action.UPDATE}
      open={!!beaconsToConfigure}
      setShowModal={() => setBeaconsToConfigure(undefined)}
      footer={
        <ModalFooter
          disabled={
            !!(beacons?.MDA3 && !mda3Configuration) ||
            !!(beacons?.Bluerange && !bluerangeConfiguration)
          }
          proceed={intl.formatMessage({
            id: 'Send configuration',
          })}
          isLoading={fetching || loadingBluerange}
          action={Action.UPDATE}
          onProceed={() => {
            if (beacons) {
              if (beacons.MDA3) {
                // Not the prettiest solution, but ConfigureMqttDevice would need to be rewritten otherwise
                // as the "sensorPolicy" is different for every organization
                for (const [organization] of mda3Configuration) {
                  const data = mda3Configuration.get(organization);

                  const checkFirmwarePackage = !!userRoles?.includes(
                    HasuraPermissions.READ_ALL,
                  );

                  if (
                    data?.sensorPolicyId &&
                    (checkFirmwarePackage ? data?.firmwarePackageId : true)
                  ) {
                    sendConfiguration(
                      {
                        UserInput: {
                          sensorPolicyId: data.sensorPolicyId,
                          topics: beacons.MDA3.map(
                            (b) => `cmd/${parseMda3Topic(b.topic)}/configure`,
                          ),
                          firmwarePackageId: data.firmwarePackageId,
                        },
                        ExcludedFromUpdates: data.excludedFromUpdates,
                        MqttBeaconIds: beacons.MDA3.map((b) => b.id),
                      },
                      hasuraHeader(
                        userRoles?.includes(HasuraPermissions.READ_ALL)
                          ? HasuraPermissions.READ_ALL
                          : HasuraPermissions.VIEW_ADMIN,
                      ),
                    ).then((data) => {
                      toast(data, {
                        message: {
                          type: 'success',
                          content: intl.formatMessage({
                            id: 'Configured beacon',
                          }),
                        },
                      });
                      setBeaconsToConfigure(undefined);
                    });
                  }
                }
              }
              if (beacons.Bluerange && bluerangeConfiguration) {
                setSceneConfigMutation(
                  {
                    BeaconInfos: beacons.Bluerange.map((b) => ({
                      beaconTopic: parseBluerangeTopic(b.topic),
                      data: [
                        bluerangeConfiguration.powerMode.toString(), // DATA 1 -> Power mode
                        bluerangeConfiguration.pirDelay.toString(), // DATA 2 -> PIR delay
                        bluerangeConfiguration.targetBrightness.toString(), // DATA 3 -> Target brightness
                        bluerangeConfiguration.aloneAtWork.toString(), // DATA 4 -> AloneAtWork
                      ],
                    })),
                    MqttSystem: MqttSystems.Bluerange,
                  },
                  hasuraHeader(HasuraPermissions.VIEW_ADMIN),
                ).then((data) => {
                  toast(data, {
                    message: {
                      type: 'success',
                      content: intl.formatMessage({ id: 'Configured beacon' }),
                    },
                  });
                  setBeaconsToConfigure(undefined);
                });
              }
            } else {
              setBeaconsToConfigure(undefined);
            }
          }}
          onCancel={() => {
            setBeaconsToConfigure(undefined);
          }}
        />
      }
    >
      {beacons &&
        Object.values(MqttSystems).map(
          (system) =>
            system in beacons && (
              <Accordion
                key={system}
                initialStateOpen
                title={
                  <FormattedMessage
                    id="Mqtt system elements"
                    values={{
                      mqttSystem: system,
                      count: beacons[system].length,
                    }}
                  />
                }
              >
                <>
                  <Transition show={system === MqttSystems.Bluerange}>
                    <BluerangeConfiguration
                      setConfiguration={setBluerangeConfiguration}
                    />
                  </Transition>
                  <Transition show={system === MqttSystems.Mda3}>
                    <div className="flex flex-col space-y-4">
                      {Object.keys(mda3BeaconOrganizations).map(
                        (organization) => (
                          <div key={organization}>
                            {/* Only show the organization name for READ_ALL as only we can see more than one organization */}
                            {userRoles?.includes(
                              HasuraPermissions.READ_ALL,
                            ) && <div className="text-lg">{organization}</div>}
                            <div className="space-y-2">
                              <PrivateWrapper
                                roleRequired={HasuraPermissions.READ_ALL}
                              >
                                <Mda3FirmwarePackageConfiguration
                                  organization={organization}
                                  setConfiguration={setMda3Configuration}
                                />
                              </PrivateWrapper>
                              <Mda3SensorPolicyConfiguration
                                organization={organization}
                                setConfiguration={setMda3Configuration}
                              />
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </Transition>
                </>
              </Accordion>
            ),
        )}
    </Modal>
  );
}
